const getImageLightness = (imageSrc) => {
  return new Promise((resolve) => {
    try {
      var img = document.getElementById("img_check_lightness"); // document.createElement("img");
      img.src = imageSrc;
      img.crossOrigin = "Anonymous";

      img.style.display = "none";
      // document.body.appendChild(img);
    
      var colorSum = 0;
      const CHECK_WIDTH = 150;
      const CHECK_HEIGHT = 150;
    
      img.onload = function() {
          // create canvas
          // var canvas = document.createElement("canvas");
          var canvas = document.getElementById("canvas_check_lightness");
          // document.body.appendChild(canvas);

          canvas.width = this.width;
          canvas.height = this.height;
    
          var ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0);
    
          var imageData = ctx.getImageData(0,0, CHECK_WIDTH, CHECK_HEIGHT);
          var data = imageData.data;
          var r,g,b,avg;
    
          for(var x = 0, len = data.length; x < len; x+=4) {
              r = data[x];
              g = data[x+1];
              b = data[x+2];
    
              avg = Math.floor((r+g+b)/3);
              colorSum += avg;
          }
          // document.body.removeChild(img);
    
          var brightness = Math.floor(colorSum / (CHECK_WIDTH * CHECK_HEIGHT));
          resolve(brightness);
      }
    } catch (err) {
      resolve(128);
    }
  })
}

export default getImageLightness