import React, { useState,useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import { TimelineMax, Power1 } from "gsap/all";
import { useHistory } from 'react-router-dom'
import Div100vh from 'react-div-100vh'

import LogoSVG from '../components/logoSvg';

import { ReactComponent as IconFb } from '../components/icons/fb.svg'
import { ReactComponent as IconIg } from '../components/icons/ig.svg'
import { ReactComponent as IconLine } from '../components/icons/line.svg'
import { ReactComponent as IconbEmail } from '../components/icons/email.svg'

import { getBrand } from '../utils/api'

const defaultCtaLink = `/select-model`

function FirstPage(props) {
  const history = useHistory();

  const [userRegistered, setUserRegistered] = useState(false)
  const [formLink, setFormLink] = useState(``)

  const pageEl = useRef();

  const coverEl = useRef();
  const coverGadientEl = useRef();
  const titleEl = useRef();
  const subtitleEl = useRef();
  const body1El = useRef();
  const body2El = useRef();
  const body3El = useRef();
  const body4El = useRef();
  const footerEl = useRef();
  
  const nextStepButton = useRef();

  const timeline = useRef();

  const { t, i18n } = useTranslation(['page1']);

  const lang = i18n.language;
  const _lang = /th/.test(lang) ? 'th' : 'en';

  const fetchData = async  (lang) => {
    const result = await getBrand(1, lang);
    const brand = result[0]

    const lead_form = _.get(brand, 'field_lead_form.0.url')
    if (lead_form) {
      setFormLink(`${process.env.REACT_APP_API_HOST}${lang === 'th' ? `/th` : ``}${lead_form}`)
    }
  }

  React.useEffect(() => {
    const submission_token = window.localStorage.getItem('bmwar_submission_token');
    if (submission_token) {
      setUserRegistered(true)
    }
  }, [])

  useEffect(() => {
    
    // const lang = i18n.language;
    // const _lang = /th/.test(lang) ? 'th' : 'en';
    // console.log({ lang, _lang })
    const _lang = window.localStorage.getItem('i18nextLng') || 'th';
    i18n.changeLanguage(_lang);
    fetchData(_lang);

    timeline.current = new TimelineMax({ paused: true });

    timeline.current
      .from(pageEl.current, 0.7, {
        display: "none",
        autoAlpha: 0,
        delay: 0.1,
        ease: Power1.easeIn
      })
      .from(coverEl.current, 0.5, {
        alpha: 0,
        ease: Power1.easeIn
      })
      // .from(coverEl.current, 0.4, {
      //   height: '100vh',
      //   delay: 0.3,
      // })
      .from(coverGadientEl.current, 0.3, { alpha: 0, ease: Power1.easeIn })
      .from(titleEl.current, 0.5, { alpha: 0, y: 10, ease: Power1.easeInOut })
      .from(subtitleEl.current, 0.5, { alpha: 0, y: 10, ease: Power1.easeInOut }, "-=0.2")
      .from(body1El.current, 0.4, { alpha: 0, ease: Power1.easeInOut }, "+=0.6")
      .from(body2El.current, 0.4, { alpha: 0, ease: Power1.easeInOut }, "-=0.1")
      .from(body3El.current, 0.4, { alpha: 0, ease: Power1.easeInOut }, "-=0.1")
      .from(body4El.current, 0.4, { alpha: 0, ease: Power1.easeInOut }, "-=0.1")
      .from(footerEl.current, 0.4, { alpha: 0, ease: Power1.easeInOut }, "-=0.1")

    timeline.current.play();
  }, [])

  useEffect(() => {
    fetchData(_lang);
  }, [_lang])

  const changePage = (e, destination) => {
    e.preventDefault();

    if (destination.substr(0) === `/`) {
      timeline.current.reverse();
      const timelineDuration = timeline.current.duration() * 1000;
      setTimeout(() => {
        const lang = i18n.language
        const _lang = /th/.test(lang) ? 'th' : 'en';

        history.push(`${destination}?lng=${_lang}`);
      }, timelineDuration);
    } else {
      window.location = destination;
    }
  }

  return (
    <Div100vh>
      <div className="body-page-1 page-series-2" ref={pageEl}>
        <div className="page-header" ref={coverEl}>
          <div className="page-header__gradient" ref={coverGadientEl} />
          <div className="page-header__inner">
            <LogoSVG theme="light" />
            <h1 ref={titleEl}>Sneak Peek.</h1>
            <p ref={subtitleEl}>The New BMW 2 Series Gran Coupé</p>
          </div>
        </div>
        <div className="page-content" ref={body1El}>
          <h2>{t('Register for exclusive Test Drive')}</h2>
          <p>{t('& View THE 2 in AR')}</p>
        </div>
        <div className="page-canvas" ref={body2El}>
          <img className="elem__all" src="/assets/images/bmw-s2-graphic@2x.png" />
        </div>
        <div className="page-content max-width-400 text-center" ref={body3El} style={{ position: 'relative' }}>
          <button
            className="normal-button font-bold"
            ref={nextStepButton}
            onClick={e => changePage(e, userRegistered ? defaultCtaLink : formLink )}
          >
            <span className="font-small color-white font-bold">{t(userRegistered ? 'Play Now' : 'Register Now')}</span>
          </button>
          <span className="tag" style={{ margin: `22px auto 11px` }}>Special Offer</span>
          <img src="/assets/images/series2-offer@2x.png" />
        </div>

        <div className="page-content content__term" ref={body4El}>
          <h3>{t('Terms & Conditions')}</h3>
          <ul className="text-sm">
            <li>{t('Prices and installments are subject to BMW Freedom Choice program')}</li>
            <li>{t('For more information, please contact your BMW Authorized Dealers')}</li>
            <li>{t('BMW Financial Services reserves the right to change terms and conditions without prior notice')}</li>
          </ul>
        </div>
        <div className="page-footer" ref={footerEl}>
          <div>
            <div className="nav-title">{t('Choose Language')}</div>
            <ul className="nav language-nav">
              <li><button className={`text-button ${_lang === 'en' ? `active` : ``}`} onClick={e => i18n.changeLanguage('en')}>English</button></li>
              <li><button className={`text-button ${_lang === 'th' ? `active` : ``}`} onClick={e => i18n.changeLanguage('th')}>ภาษาไทย</button></li>
            </ul>
          </div>
          <div>
            {/* <div className="nav-title">Share on</div>
            <ul className="nav">
              <li><a href="#"><IconFb /></a></li>
              <li><a href="#"><IconIg /></a></li>
              <li><a href="#"><IconLine /></a></li>
              <li><a href="#"><IconbEmail /></a></li>
            </ul> */}
          </div>
        </div>
      </div>
    </Div100vh>
  );
};

export default FirstPage;