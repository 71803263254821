import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import _ from 'lodash'
import Header from '../components/Header'
import DeviceDetector from "device-detector-js";
import { Helmet } from "react-helmet";
import Div100vh from 'react-div-100vh'

import { getModel, getVariant, getVariantOption, getOfferPage } from '../utils/api';

const MOCKUP_SRC = ''; // 'https://ar-experience-storage.s3-ap-southeast-1.amazonaws.com/models/ru/bmw_THE_2_ver_3_40.glb'
const MOCKUP_IOS_SRC = ''; // 'https://ar-experience-storage.s3-ap-southeast-1.amazonaws.com/models/ru/bmw_THE_2_ver_3_40.usdz'

function FourthPage(props) {
  const { t, i18n } = useTranslation(['page4']);
  const tabs = [
    // 'Enter Competition',
    'Exclusive Offers'
  ]

  const [OS, setOS] = React.useState('Android');
  const [model, setModel] = React.useState(null);
  const [variant, setVariant] = React.useState(null);
  const [option, setOption] = React.useState(null);
  // const [offerPage, setOfferPage] = React.useState({});

  const [activeTab, setActiveTab] = React.useState(1);
  const history = useHistory();
  let { modelId, variantId, optionId } = useParams()

  const vrButton = useRef();
  
  useEffect(() => {
    const deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    // console.log({ userAgent, device })
    const os = device.os.name;
    // console.log({ os })
    setOS(os);
  }, []);

  useEffect(() => {
    fetchData(modelId, variantId, optionId, i18n.language)
  }, [modelId, variantId, optionId, i18n.language])

  const changePage = (e, destination) => {
    e.preventDefault();
    // timeline.current.reverse();
    // const timelineDuration = timeline.current.duration() * 1000;
    const timelineDuration = 0;
    setTimeout(() => {
      history.push(destination);
    }, timelineDuration);
  }

  const fetchData = async (modelId, variantId, optionId, lang = 'en') => {
    const _lang = /th/.test(lang) ? 'th' : 'en';

    const model = await getModel(modelId);
    setModel(model);

    const variant = await getVariant(modelId, variantId, lang)
    setVariant(variant);

    const option = await getVariantOption(modelId, variantId, optionId, _lang);
    setOption(option);

    // const offerPage = await getOfferPage(); // Call both language 
    // setOfferPage(offerPage);
  };

  const src = _.get(option, 'android_src', MOCKUP_SRC)
  const ios_src = _.get(option, 'ios_src', MOCKUP_IOS_SRC)

  const mini_src = _.get(option, 'mini_android_src', MOCKUP_SRC)
  const mini_ios_src = _.get(option, 'mini_ios_src', MOCKUP_IOS_SRC)

  const promotionBody = _.get(variant, `finance_promotion`) || _.get(model, `finance_promotion`);

  let href;
  if (OS === 'Android') href = `intent://arvr.google.com/scene-viewer/1.0?file=${src}&mode=ar_preferred#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
  else if (OS === 'iOS' || OS === 'Mac') href = `${ios_src}`;
  return (
    <Div100vh>
      <div className="body-page-4">
        <Helmet>
          <title>{_.get(option, 'title', 'Model')} | {process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <Header
          onClickClose={e => history.goBack()}
        />
        <div className="content__container">
          <model-viewer 
            poster={_.get(option, 'photo.url')}
            src={mini_src} 
            ios-src={mini_ios_src}
            alt={_.get(option, 'title', 'BMW')}
            auto-rotate
            camera-controls
            camera-orbit="30deg 75deg 75%"
            quick-look-browsers="safari chrome"
            shadow-intensity="1"
            ar-modes="scene-viewer quick-look"
          > 
            {href ? (
              <a className="ar-button" rel="ar" href={href}>
                <img src="/assets/images/ar-icon-button.png" alt="icon" className="button-icon" />
                <p className="font-small">{t(`View in AR`)}</p>
              </a>
            ) : (
              <button slot="ar-button" className="ar-button" ref={vrButton}>
                <img src="/assets/images/ar-icon-button.png" alt="icon" className="button-icon" />
                <p className="font-small">{t('View in AR')}</p>
              </button>
            )}
          </model-viewer>
          <div className="content__inner">
            <div dangerouslySetInnerHTML={{ __html: promotionBody}} />
            <div className="content__inner-actions">
              <button className="cta-button secondary-button color-white font-bold" onClick={e => changePage(e, `/select-model`)}>{t('Select another model')}</button>
            </div>
          </div>

          {/* Body */}
          {/*
          <div className="content__body">
            <div className="tab-header">
              {tabs.length > 1 && tabs.map((tabLabel, index) => (
                <button
                  key={`button-tab-${index}`}
                  className={`tab-button ${index === activeTab ? `active` : ``}`}
                  onClick={e => setActiveTab(index)}
                >
                  {t(tabLabel)}
                </button>
              ))}
            </div>
            <div className="tab-container">
              <div className={`tab ${activeTab === 0 ? `active` : ``}`}>
                <iframe style={{ width: '100%', height: '100%' }} title="Competition" src={`${process.env.REACT_APP_API_HOST}${i18n.language === 'th' ? '/th' : '/'}/node/10`}></iframe>
              </div>
              <div className={`tab ${activeTab === 1 ? `active` : ``}`}>
                <div className="offer text-padding">
                  <div>
                    <h2>{_.get(offerPage, `${i18n.language}.title`)}</h2>
                    <span dangerouslySetInnerHTML={{ __html: _.get(model, `finance_promotion`) }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
          {/* End of Body */} 
          {/*
          <div className="bottom-actions">
            <button className="cta-button normal-button color-white">Share</button>
            <button className="cta-button secondary-button color-white" onClick={e => changePage(e, `/select-model`)}>Select another model</button>
          </div>
          */}
        </div>
      </div>
    </Div100vh>
  );
};

export default FourthPage;

/*
           
*/