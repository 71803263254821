import React, { Suspense } from 'react';
import _ from 'lodash'
import ReactGA from 'react-ga';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { useLocalStorage, useSearchParam } from "react-use";

import './App.scss';
import 'flickity/dist/flickity.min.css';
import DeviceDetector from "device-detector-js";
 
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'

// import LandingPage from './pages/LandingPage';
import LandingPage from './pages/LandingPageSeries2';
import SelectModel from './pages/SelectModel';
import SelectVariant from './pages/SelectVariant.js';
import FourthPage from './pages/fourthPage';
import ThankYouPage from './pages/thankYouPage';
import SorryPage from './pages/sorryPage';
import DesktopPage from './pages/desktopPage';
import SampleForm from './pages/sampleForm';
import OfferForm from './pages/OfferForm';

gsap.registerPlugin(CSSPlugin)

const Loader = () => (
  <div className="app-loading">
    {/* <div>loading...</div> */}
    {/* <Logo /> */}
  </div>
);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
const trackingId = process.env.REACT_APP_GA_TRACKINGID
ReactGA.initialize(trackingId);

function App() {

  const [enterable, setEnterable] = React.useState(true);

  const submission_token = useSearchParam('submission_token');

  React.useEffect(() => {
    const deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    const deviceType = device.device.type;
    if (deviceType === 'browser') setEnterable(false);
  }, [])

  React.useEffect(() => {
    if (window && !_.isEmpty(submission_token)) {
      window.localStorage.setItem('bmwar_submission_token', submission_token);
    }

    console.log(`localStorage`, window.localStorage.getItem('bmwar_submission_token'))
  }, [submission_token])

  return (
    <Suspense fallback={<Loader />}>
      {!enterable
      ? <DesktopPage /> : (
        <Router history={history}>
          <Route path="/" render={({location}) => {
            ReactGA.set({ page: location.pathname }); // Update the user's current page
            ReactGA.pageview(location.pathname); // Record a pageview for the given page
            return null;
          }} />
          <Switch>
            <Route path="/" exact component={LandingPage} />
            <Route path="/select-model" component={SelectModel} />
            <Route
              exact
              path="/models/2/variants/4/options/7/ar"
              render={() => (
                <Redirect to="/models/19/variants/20" />
              )}
            />
            <Route exact path="/models/:modelId/variants/:variantId" component={SelectVariant} />
            <Route exact path="/models/:modelId/variants/:variantId/options/:optionId/ar" component={FourthPage} />
            <Route path="/thank-you" component={ThankYouPage} />
            <Route path="/offer-form" component={OfferForm} />
            <Route path="/sorry" component={SorryPage} />
            <Route path="/form" component={SampleForm} />
          </Switch>
        </Router>
      )}
    </Suspense>
  );
}

export default App;
