import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TimelineMax, Power1 } from "gsap/all";

import Header from '../components/Header';

function SorryPage(props) {
  const history = useHistory();

  const timeline = new TimelineMax({ paused: true });

  const headerEl = useRef();
  const closeButton = useRef();
  const textEl = useRef();

  useEffect(() => {
    timeline
      .from(headerEl.current, 0.5, {
        alpha: 0,
        ease: Power1.easeIn
      })
      .from(closeButton.current, 0.5, {
        alpha: 0,
        ease: Power1.easeIn
      })
      .from(textEl.current, 0.7, {
        display: "none",
        autoAlpha: 0,
        y: 25,
        delay: 0.1,
        ease: Power1.easeInOut
      })

    timeline.play();
  }, [])

  const changePage = (e, destination) => {
    e.preventDefault();
    timeline.reverse();
    const timelineDuration = timeline.duration() * 1000;
    setTimeout(() => {
      history.push(destination);
    }, timelineDuration);
  }

  return (
    <div className="body-thank-you">
      <div className="logo-wrapper" ref={headerEl}>
        <Header showCloseButton={false} />
      </div>
      <button onClick={(e) => changePage(e, '/')} className="close-button close-button-position" ref={closeButton}>
        <img src="../assets/images/close-icon.png" alt="icon" className="close-icon" />
      </button>
      <div ref={textEl}>
        <h3 className="uppercase color-dark-gray text-center">Sorry.</h3>
        <p className="color-dark-gray text-center">The BMW AR experience is only available <br />for <span className="font-bold">AR compatible mobile devices</span>.</p>
      </div>
    </div>
  );
}

export default SorryPage;
