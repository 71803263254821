import React from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../components/Header';

import useQuery from '../utils/useQuery';

export default () => {
  const history = useHistory();
  const query = useQuery();

  const url = query.get("url")

  return (
    <div className="body-page-4">
      <Header onClickClose={e => history.goBack()} />
      <iframe title="Offer" style={{ position: 'relative', top: '116px', width: '100%', height: 'calc(100vh - 116px)' }} src={url}></iframe>
    </div>
  )
}