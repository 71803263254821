import React from 'react';
import _ from 'lodash';
// import getImageLightness from '../utils/getImageLightness'

function FlatCard(props) {
  const [textColor, setTextColor] = React.useState('dark-grey');
  const handleClickCard = (e) => {
    if (props.onClickCard) props.onClickCard(e);
  }

  // const image = _.get(props, 'image');

  // const checkTextColor = async (image) => {
  //   const result = await getImageLightness(image)
  //   console.log(`check logo placement area (150x150) lightness (0 - 255): ${result} [${image}]`)

  //   // 255 = light, 0 = dark
  //   const _color = result > 128 ? 'dark-grey' : 'white'
  //   setTextColor(_color);
  //   console.log(`set textColor to: ${_color}`)
  // }
  
  // React.useEffect(() => {
  //   checkTextColor(image);
  // }, [image])
  
  return (
    <div className={`flat-card-wrapper ${_.get(props, 'active') ? 'card-active' : ''}`} onClick={e => handleClickCard(e)}>
      <p className={`font-tiny name-text color-${textColor}`}>{_.get(props, 'name')}</p>
      <img src={_.get(props, 'image')} alt="thumbnail" className="thumbnail-image" />
    </div>
  );
}

export default FlatCard;
