import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { TimelineMax, Power1 } from "gsap/all";
import Div100vh from 'react-div-100vh'

import Header from '../components/Header';

function ThankYouPage(props) {
  const history = useHistory();

  const timeline = new TimelineMax({ paused: true });

  const headerEl = useRef();
  const closeButton = useRef();
  const textEl = useRef();

  useEffect(() => {
    timeline
      .from(headerEl.current, 0.5, {
        alpha: 0,
        ease: Power1.easeIn
      })
      .from(closeButton.current, 0.5, {
        alpha: 0,
        ease: Power1.easeIn
      })
      .from(textEl.current, 0.7, {
        display: "none",
        autoAlpha: 0,
        y: 25,
        delay: 0.1,
        ease: Power1.easeInOut
      })

    timeline.play();
  }, [])

  const changePage = (e, destination) => {
    e.preventDefault();
    timeline.reverse();
    const timelineDuration = timeline.duration() * 1000;
    setTimeout(() => {
      history.push(destination);
    }, timelineDuration);
  }

  return (
    <Div100vh>
      <div className="body-thank-you">
        <div ref={headerEl}>
          <Header showCloseButton={false} onClickClose={e => changePage(e, '/')} />
        </div>
        <div ref={textEl}>
          <h3 className="uppercase color-dark-gray text-center">Thankyou.</h3>
          <p className="color-dark-gray text-center">Our BMW agent will contact you shortly.</p>
        </div>
      </div>
    </Div100vh>
  );
}

export default ThankYouPage;
