import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { TimelineMax, Power1 } from "gsap/all";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import { useHistory, useParams } from 'react-router-dom'

import Header from '../components/Header'
import FlatCard from '../components/flatCard.js';
import { getVariant, listVariantOptionsByVariantId } from '../utils/api';
// import getImageLightness from '../utils/getImageLightness'

function ThirdPage() {
  const { i18n } = useTranslation(['page3']);

  const [logoTheme, setLogoTheme] = useState('dark');
  const [loaded, setLoaded] = useState(false);
  const [variant, setVariant] = useState({});
  const [options, setOptions] = useState([]);
  const [activeCard, setActiveCard] = useState(null);

  let { modelId, variantId } = useParams()
  const history = useHistory();

  const headerEl = useRef();
  const coverImageEl = useRef();
  const contentEl = useRef();
  const variationOptionsEl = useRef();
  const buttonEl = useRef();

  const timeline = React.useRef();

  useEffect(() => {
    const lang = i18n.language;
    const _lang = /en/.test(lang) ? 'en' : 'th';
    console.log({ lang, _lang })
    i18n.changeLanguage(_lang);

    // Clear Data
    setLoaded(false);
    setVariant({});
    setOptions([]);
    setActiveCard(null);

    fetchData(modelId, variantId, i18n.language);
  }, [modelId, variantId, i18n.langauge]);

  useEffect(() => {
    if (loaded) {
      timeline.current = new TimelineMax({ paused: true });
      timeline.current
        .from(headerEl.current, 0.5, {
          alpha: 0,
          ease: Power1.easeIn
        })
        .from(coverImageEl.current, 0.5, {
          alpha: 0,
          y: 10,
          ease: Power1.easeIn
        }, "-=0.2")
        .from(contentEl.current, 0.4, {
          autoAlpha: 0,
          y: 25,
          ease: Power1.easeInOut
        }, "-=0.2")
        .from(variationOptionsEl.current, 0.3, {
          alpha: 0,
          y: 25,
          ease: Power1.easeIn
        }, "-=0.3")
        .from(buttonEl.current, 0.4, {
          autoAlpha: 0,
          y: 25,
          ease: Power1.easeInOut
        }, "-=0.2")

        timeline.current.play();
      }
  }, [loaded])

  const changePage = (e, destination) => {
    e.preventDefault();
    timeline.current.reverse();
    const timelineDuration = timeline.current.duration() * 1000;
    setTimeout(() => {
      history.push(destination);
    }, timelineDuration);
  }

  const fetchData = async (modelId, variantId, lang = 'en') => {
    const _lang = /th/.test(lang) ? 'th' : 'en';

    const variant = await getVariant(modelId, variantId, _lang);
    const options = await listVariantOptionsByVariantId(modelId, variantId, _lang);
    setVariant(variant);
    setOptions(options);
    setLoaded(true);

    // const coverImage = _.get(variant, 'photo.url');
    // const result = await getImageLightness(coverImage)
    // console.log(`check logo placement area (150x150) lightness (0 - 255): ${result} [${coverImage}]`)
    // 255 = light, 0 = dark
    // const _theme = result > 128 ? 'dark' : 'light'
    // setLogoTheme(_theme);
  };

  if (!loaded || !variant) return <div></div>

  return (
    <div className="body-page-3">
      <Helmet>
        <title>{_.get(variant, 'title', 'BMW AR Experience')} | {process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <div ref={headerEl}>
        <Header logoTheme={logoTheme} showCloseButton={false} />
      </div>
      {/* <div
        className="coverimage-container" ref={coverImageEl} 
        style={{
          backgroundImage: `url(${_.get(variant, 'photo.url')}`
        }}
      /> */}
      {/* <div className="content-wrapper text-padding" ref={contentEl}>
        <h3 className="uppercase">{_.get(variant, 'title')}</h3>
        <div className="margin-top-10 font-small" dangerouslySetInnerHTML={{__html: _.get(variant, 'body') }} />
      </div> */}
      {/* <div className="content-wrapper text-padding" ref={contentEl}>
        <h3 className="uppercase">{_.get(variant, 'title')}</h3>
      </div> */}
      <div className="content-wrapper text-padding" style={{ paddingTop: 100 }} ref={contentEl}>
        <h3 className="uppercase">{_.get(variant, 'options_prefix_header')}</h3>
      </div>
      <div ref={variationOptionsEl}>
        {options.length > 0 ? (
          <>
            <div>
              {/* <p className="title-wrapper font-small font-bold">{_.get(variant, 'options_prefix_header')}</p> */}
              <div className="options__container">
                {_.map(options, (option, index) => (
                  <div className="option__item" key={`variation_option-${index}`}>
                    <FlatCard
                      image={_.get(option, 'photo.url')}
                      name={option.title}
                      // onClickCard={() => setActiveCard(index)}
                      onClickCard={e => changePage(e, `/models/${modelId}/variants/${variantId}/options/${option.id}/ar`)}
                      active={activeCard === index}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="button-wrapper" ref={buttonEl}>
              {/* <button
                className="normal-button"
                disabled={!selectedOption}
                onClick={e => changePage(e, `/models/${modelId}/variants/${variantId}/options/${selectedOption.id}/ar`)}
              >
                <span className="font-small color-white">{t('View in AR')}</span>
              </button> */}
            </div>
          </>
        ) : (
          <div style={{ opacity: 0.5, padding: 32 }}>
            <p className="font-medium" style={{ textAlign: 'center' }}>No variant options</p>
          </div>
        )}
      </div>

      {/* move content down */}
      {/* <div
        className="coverimage-container" ref={coverImageEl} 
        style={{
          backgroundImage: `url(${_.get(variant, 'photo.url')}`
        }}
      /> */}
      <div className="content-wrapper text-padding" ref={contentEl}>
        <h3 className="uppercase">{_.get(variant, 'title')}</h3>
        <div className="margin-top-10 font-small" dangerouslySetInnerHTML={{__html: _.get(variant, 'body') }} />
      </div>
      {/* end of move content down */}
    </div>
  );
}

export default ThirdPage;
