import React from 'react'
import LogoSvg from './logoSvg';

export default ({ logoTheme = 'dark', showCloseButton = true, onClickClose }) => (
  <header className="header">
    <div className="logo-wrapper">
      <LogoSvg theme={logoTheme} />
    </div>
    {showCloseButton && (
      <button onClick={onClickClose} className="close-button close-button-position">
        <img src="/assets/images/close-icon.png" alt="icon" className="close-icon" />
      </button>
    )}
  </header>
)