import React, { useRef, useEffect, useState } from 'react';
import { TimelineMax, Power1 } from "gsap/all";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Div100vh from 'react-div-100vh'

import Flickity from 'flickity';
import FlickityTransformer from 'flickity-transformer';
import { useHistory } from 'react-router-dom'
import _ from 'lodash';

import Header from '../components/Header'
import { listCoverflow } from '../utils/api';
import getImageLightness from '../utils/getImageLightness'

function SecondPage() {
  const { t, i18n } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const [slideData, setSlideData] = useState({});
  const [progress, setProgress] = useState(0);

  const history = useHistory();

  const headerEl = useRef();
  const carouselContainerEl = useRef();
  const instructionEl = useRef();

  const timeline = React.useRef();

  useEffect(() => {
    const BRAND_ID = 1;
    fetchData(BRAND_ID, i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (loaded && !_.isEmpty(slideData)) {
      const flkty = new Flickity('.Slider', {
        setGallerySize: false,
        pageDots: true
      })
  
      flkty.on('staticClick', function(event, pointer, cellElement, cellIndex) {
        const targetSlide = slideData[cellIndex];        
        changePage(event, `/models/${targetSlide.model.id}/variants/${targetSlide.id}`);
      });

      flkty.on('scroll', function( progress ) {
        setProgress(progress)
      });

      new FlickityTransformer(flkty, [
        {
          name: 'scale',
          stops: [
            [-200, 0.8],
            [0, 1],
            [200, 0.8]
          ]
        },
      ]);
    }
  }, [loaded, slideData]);

  useEffect(() => {

    if (loaded) {
      timeline.current = new TimelineMax({ paused: true });
      timeline.current
        .from(headerEl.current, 0.5, {
          alpha: 0,
          ease: Power1.easeIn
        })
        .from(carouselContainerEl.current, 0.5, {
          // display: "none",
          alpha: 0,
          y: -25,
          ease: Power1.easeInOut
        })
        .from(instructionEl.current, 0.5, {
          alpha: 0,
          ease: Power1.easeIn
        }, "-=0.2")

      timeline.current.play(); 
    } 
  }, [loaded])

  const fetchData = async (brandId, lang = 'en') => {

    const _lang = /th/.test(lang) ? 'th' : 'en';
    const slides = await listCoverflow(brandId, _lang);

    setSlideData(slides);
    setLoaded(true);
    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i]
      await getImageLightness(slide.coverImageURL)
        .then(result => {
          // 255 = light, 0 = dark
          const theme = result > 128 ? 'dark' : 'light'
          const newSlides = _.map(slides, (slide, slideIndex) => {
            if (i === slideIndex) return { ...slide, theme }
            return slide;
          })
          setSlideData(newSlides)
        })
        .catch(err => console.log(err))
    }
  };

  const changePage = (e, destination) => {
    e.preventDefault();
    timeline.current.reverse();
    const timelineDuration = timeline.current.duration() * 1000;
    setTimeout(() => {
      history.push(`${destination}?lng=${i18n.language}`);
    }, timelineDuration);
  }

  if (!loaded) return <div />
  const len = slideData.length
  return (
    <Div100vh>
      <div className="body-page-2">
        <Helmet>
          <title>Select Model | {process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <div ref={headerEl}>
          <Header logoTheme="dark" showCloseButton={false} />
        </div>
        <div className="slider__container" ref={carouselContainerEl}>
          {!_.isEmpty(slideData) && (
            <div className="Slider">
              {_.map(slideData, (item, index) => {

                const tx = ((progress * (len - 1)) - index) * -200;
                const title = _.get(item, 'title', '0')
                const bigNo = title.match(/\d/)
                const coverImage = _.get(item, 'coverImageURL')
                return (
                  <div className="Slide slider-wrapper" key={`slide-${index}`}>
                    <h3 className="uppercase separate-char">THE</h3>
                    <h3 className="font-big">{bigNo}</h3>
                    <div className="slider-image-container">
                      <img
                        src={coverImage}
                        alt="car"
                        className="slider-image"
                        style={{
                          transform: `translateX(${tx}px) translateZ(0)`
                        }}
                      />
                    </div>
                    <div className="slider-bottom">
                      <h3 className="uppercase text-center">{title}</h3>
                      <div className="separate-line" />
                      <p className="font-tiny text-center">{_.get(item, 'tagline') || _.get(item, 'model.short_description')}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="dots-text-wrapper" ref={instructionEl}>
          <p className="font-small font-bold">{t('Select Model')}</p>
        </div>
      </div>
    </Div100vh>
  );
};

export default SecondPage;
