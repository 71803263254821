import axios from "axios";
import _ from "lodash";

const baseURL = process.env.REACT_APP_API_HOST;
const USERNAME = process.env.REACT_APP_API_USERNAME;
const PASSWORD = process.env.REACT_APP_API_PASSWORD;
const S3BUCKET = process.env.REACT_APP_API_S3BUCKET;
const CDNHOST = process.env.REACT_APP_CDNHOST;
/*
 * Parse
 **/

const parseBrand = (data) => {
  return data;
}
const parseModel = data => {
  const photo = _.get(data, 'field_photos.0', {})
  return {
    id: data.nid[0].value,
    title: data.title[0].value,
    intro: data.field_intro[0].value,
    photo: {
      ...photo,
      url: _.get(photo, 'url').replace(S3BUCKET, CDNHOST)
    },
    finance_promotion: _.get(data, 'field_finance_promotion[0].value', '').replace(/\/sites\/default/g, `${process.env.REACT_APP_API_HOST}/sites/default`),
    short_description: data.field_short_description[0].value 
  };
}

const parseVariant = data => {
  const photo = _.get(data, 'field_photos.0', {})
  const coverImage = _.get(data, 'field_cover_image.0', {})
  return {
    id: _.get(data, 'nid[0].value'),
    title: _.get(data, 'title[0].value'),
    tagline: _.get(data, 'field_variant_tagline[0].processed'),
    body: _.get(data, 'body[0].processed'),
    photo: {
      ...photo,
      url: _.get(photo, 'url').replace(S3BUCKET, CDNHOST)
    },
    coverImage: {
      ...coverImage,
      url: _.get(coverImage, 'url', '').replace(S3BUCKET, CDNHOST)
    },
    finance_promotion: _.get(data, 'field_variant_finance_promotion[0].value', '').replace(/\/sites\/default/g, `${process.env.REACT_APP_API_HOST}/sites/default`),
    model_id: _.get(data, 'field_model[0].target_id'),
    options_prefix_header: _.get(data, 'field_options_prefix_header[0].value'),
  };
}

const parseVariationOption = data => {
  const android_src = _.get(data, 'field_android_model_file.0.url', '').replace(S3BUCKET, CDNHOST)
  const ios_src = _.get(data, 'field_ios_model_file.0.url', '').replace(S3BUCKET, CDNHOST)

  const photo = _.get(data, 'field_photos.0', {})
  return {
    id: data.nid[0].value,
    title: _.get(data, 'title[0].value'),
    intro: _.get(data, 'field_intro[0].processed'),
    photo: {
      ...photo,
      url: _.get(photo, 'url').replace(S3BUCKET, CDNHOST)
    },
    android_src,
    ios_src,
    mini_android_src: _.get(data, 'field_mini_android_model_file_.0.url', android_src).replace(S3BUCKET, CDNHOST),
    mini_ios_src: _.get(data, 'field_mini_ios_model_file.0.url', ios_src).replace(S3BUCKET, CDNHOST),
  };
}

const parseOfferPage = data => {
  const banner = _.get(data, 'field_banner.0', {})
  return {
    id: data.nid[0].value,
    title: data.title[0].value,
    langcode: data.langcode[0].value,
    body: data.body[0].processed,
    // banner: data.field_banner[0],
    banner: {
      ...banner,
      url: _.get(banner, 'url').replace(S3BUCKET, CDNHOST)
    },
    cta_button_text: data.field_cta_button_text[0].value,
    form: data.field_form[0].uri
  }
} 

/*
 * API
 **/

const getBrand = async (brandId, lang = 'en') => {
  const apiUrl = `${baseURL}/api/brands/${brandId}?lang=${lang}`;
  try {
    const result = await axios({
      method: "get",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      auth: {
        username: USERNAME,
        password: PASSWORD
      },
    });
    return _.uniqBy(_.map(_.get(result, "data"), parseBrand), item => item.id)
  } catch (error) {
    console.log("ListByBrandID error", { error });
    return { status: false };
  }
};


const getModel = async (modelId, lang = 'en') => {
  const apiUrl = `${baseURL}/api/brands/1/models/${modelId}?lang=${lang}`;
  try {
    const result = await axios({
      method: "get",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      auth: {
        username: USERNAME,
        password: PASSWORD
      },
    });
    return parseModel(_.get(result, "data.0"))
  } catch (error) {
    console.log(error);
    return { status: false };
  }
}

const listModelsByBrandId = async (brandId, lang = 'en') => {
  const apiUrl = `${baseURL}/api/brands/${brandId}/models?lang=${lang}`;
  try {
    const result = await axios({
      method: "get",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      auth: {
        username: USERNAME,
        password: PASSWORD
      },
    });
    return _.uniqBy(_.map(_.get(result, "data"), parseModel), item => item.id)
  } catch (error) {
    console.log("ListByBrandID error", { error });
    return { status: false };
  }
};

const listVariationsByModelId = async (modelId, lang = 'en') => {
  const apiUrl = `${baseURL}/api/brands/1/models/${modelId}/variants?lang=${lang}`;
  try {
    const result = await axios({
      method: "get",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      auth: {
        username: USERNAME,
        password: PASSWORD
      },
    });
    return _.uniqBy(_.map(_.get(result, "data"), parseVariant), item => item.id)
  } catch (error) {
    console.log("ListCoverflow error", { error });
    return { status: false };
  }
};

const getVariant = async (modelId, variantId, lang = 'en') => {
  const apiUrl = `${baseURL}/api/brands/1/models/${modelId}/variants/${variantId}?lang=${lang}`;
  try {
    const result = await axios({
      method: "get",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      auth: {
        username: USERNAME,
        password: PASSWORD
      },
    });
    return parseVariant(_.get(result, "data.0"))
  } catch (error) {
    console.log(error);
    return { status: false };
  }
}

const getVariantOption = async (modelId, variantId, optionId, lang = 'en') => {
  const apiUrl = `${baseURL}/api/brands/1/models/${modelId}/variants/${variantId}/options/${optionId}?lang=${lang}`;
  try {
    const result = await axios({
      method: "get",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      auth: {
        username: USERNAME,
        password: PASSWORD
      },
    });
    return parseVariationOption(_.get(result, "data.0"))
  } catch (error) {
    console.log(error);
    return { status: false };
  }
}

const listVariantOptionsByVariantId = async (modelId, variantId, lang = 'en') => {
  const apiUrl = `${baseURL}/api/brands/1/models/${modelId}/variants/${variantId}/options?lang=${lang}`;
  try {
    const result = await axios({
      method: "get",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      auth: {
        username: USERNAME,
        password: PASSWORD
      },
    });
    return _.uniqBy(_.map(_.get(result, "data"), parseVariationOption), item => item.id)
  } catch (error) {
    console.log(error);
    return { status: false };
  }
};

const listCoverflow = async (brandId = 1, lang = 'en') => {
  const models = await listModelsByBrandId(brandId, lang);
  const variations = await Promise.all(models.map(model => listVariationsByModelId(model.id, lang)))
  
  // List models + variants
  return _.map(_.flatten(variations), variation => {
    const model = _.find(models, model => model.id === variation.model_id)
    const coverImageURL = _.get(variation, 'coverImage.url') || _.get(model, 'photo.url')
    return {
      ...variation,
      coverImageURL,
      model,
    }
  });
}

const callApi = async (url, options) => {
  const apiUrl = `${baseURL}/${url}`;
  try {
    const result = await axios({
      method: _.get(options, 'method', 'get'),
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*',
      },
      auth: {
        username: USERNAME,
        password: PASSWORD
      },
    });
    return _.get(result, "data")
  } catch (error) {
    console.log(error);
    return { status: false };
  }
}

const getOfferPage = async () => {
  const [en, th] = await Promise.all([
    callApi(`api/brands/1/offers?lang=en`).then(data => data[0]).then(parseOfferPage),
    callApi(`api/brands/1/offers?lang=th`).then(data => data[0]).then(parseOfferPage),
  ])
  return { en, th }
}


export {
  getBrand,
  listModelsByBrandId,
  getModel,
  listCoverflow,
  listVariationsByModelId,
  getVariant,
  listVariantOptionsByVariantId,
  getVariantOption,
  getOfferPage
};
