import React from 'react';
import Div100vh from 'react-div-100vh'

import Header from '../components/Header';

function DesktopPage(props) {
  return (
    <Div100vh>
      <div className="desktop-page">
        <Header showCloseButton={false} />
        <div className="content-wrapper">
          <div className="text-wrapper">
            <h1 className="font-large color-dark-gray uppercase text-center">Sorry.</h1>
            <p className="color-dark-gray text-center margin-top-20">The BMW AR experience is only <br />available for <span className="font-bold">AR compatible mobile <br />devices</span>.</p>
          </div>
          <img src="../assets/images/desktop-image.jpg" alt="sample-web" className="sample-image" />
        </div>
      </div>
    </Div100vh>
  );
}

export default DesktopPage;