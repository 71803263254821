import React from 'react';

function LogoSVG({ theme = 'dark' }) {
    const lineFill = theme === 'dark' ? '#6F6F6F' : '#FFFFFF'
    return (
        <svg className="logo" width="48" height="48" viewBox="0 0 48 48">
            <g fill="none">
                {/* outer circle */}
                <path fill={lineFill} d="M23.94 0C10.72 0 0 10.72 0 23.94c0 13.223 10.72 23.942 23.94 23.942 13.223 0 23.942-10.72 23.942-23.941C47.882 10.719 37.162 0 23.94 0zm22.385 23.94c0 12.363-10.021 22.385-22.384 22.385S1.557 36.303 1.557 23.94 11.579 1.557 23.94 1.557 46.325 11.579 46.325 23.94z"/>
                
                {/* inner circle */}
                <circle cx={24} cy={24} r={14} fill="#FFFFFF" stroke={lineFill} />
                
                {/* BMW */}
                <g fill={lineFill}>
                    <path d="M28.928 10.968c.325.35.794.912 1.057 1.254l4.848-3.077c-.242-.316-.628-.798-.916-1.137l-3.07 2.03-.21.18.156-.228 1.356-2.706-.959-.958-2.707 1.355-.228.157.182-.21 2.031-3.069c-.358-.304-.701-.582-1.139-.92l-3.077 4.843c.39.3.904.74 1.232 1.04L30.38 8.02l.186-.136-.137.186-1.502 2.9zM17.928 4.83l1.324-2.968.085-.263-.024.275.139 3.965c.464.043.944.108 1.417.187L20.654.153C19.992.08 19.334.028 18.675 0L17.37 3.239l-.046.24-.047-.24L15.974 0c-.659.028-1.318.08-1.98.153l-.215 5.873c.473-.079.953-.142 1.418-.187l.14-3.965-.025-.275.085.263L16.72 4.83h1.207zM7.214 9.51c.766-.807 1.2-1.739.434-2.628-.417-.483-1.117-.589-1.697-.35l-.057.022.018-.05c.086-.23.14-.962-.483-1.468-.304-.246-.685-.337-1.071-.298-.72.073-1.272.565-2.779 2.244C1.125 7.489.463 8.288.066 8.806l4.137 3.925c1.371-1.518 1.93-2.082 3.011-3.221zm-5.398-.96c.833-1.02 1.719-1.944 2.12-2.31.126-.115.264-.23.432-.276.272-.074.552.118.615.397.063.28-.113.546-.308.766-.44.497-2.057 2.187-2.057 2.187l-.802-.764zm1.62 1.537S5.01 8.423 5.524 7.891c.204-.211.335-.34.475-.408.182-.089.38-.107.558.011.174.117.254.318.21.518-.051.238-.245.465-.406.638l-2.086 2.232-.839-.795z" transform="translate(6.616 2.815)"/>
                </g>
                {/* Blue Pattern */}
                <path fill="#0066B1" d="M23.94 9.744V23.94H9.745c0-7.846 6.35-14.197 14.197-14.197zM38.138 23.94c0 7.847-6.351 14.198-14.197 14.198V23.941h14.197z"/>
            </g>
        </svg>
    );
}

export default LogoSVG;