import React from 'react';

function SampleForm(props) {
  return (
    <>
      <input type="text" className="simple-field" placeholder="Name" />
      <input type="text" className="simple-field" placeholder="Name" />
      <div className="icon-field-wrapper">
        <button className="button-in-field">
          <img src="../../assets/images/publish-icon.png" alt="icon" className="button-icon-field" />
        </button>
        <input type="text" className="simple-field icon-field" placeholder="Your Selfie" />
      </div>
      <div className="icon-field-wrapper">
        <img src="../../assets/images/check-icon.png" alt="icon" className="check-icon-field" />
        <input type="text" className="simple-field icon-field" placeholder="Your Selfie" />
      </div>

      <select id="cars" name="cars" className="simple-field select-field">
        <option value="volvo">Volvo</option>
        <option value="saab">Saab</option>
        <option value="fiat">Fiat</option>
        <option value="audi">Audi</option>
      </select>
    </>
  );
};

export default SampleForm;
